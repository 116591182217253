<template>
  <!-- begin::IncidentTypes edit -->
  <div>
    <div class="manager-title">
      <div>
        {{
          IncidentTypeID > 0
            ? $t("INCIDENTS.TYPES.TITLE_EDIT")
            : $t("INCIDENTS.TYPES.TITLE_ADD_2")
        }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <div class="white-box shadow-box">
      <v-form ref="formBasic" v-model="Valid" lazy-validation class="form">
        <div class="manager-subtitle">
          {{ $t("INCIDENTS.TYPES.SUBTITLE_EDIT") }}
        </div>

        <div class="row form-group m-0">
          <label>
            {{ $t("INCIDENTS.TYPES.NAME_1") }}
          </label>
        </div>
        <div class="row">
          <div
            v-for="(aLang, a) in LanguageList"
            :key="a"
            class="form-group col-lg-4"
          >
            <label>
              {{ aLang.name }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Name[aLang.lang]"
              required
              :rules="[rules.required]"
            />
          </div>
        </div>

        <div class="row mb-0">
          <div class="col-lg-12 form-group mb-0">
            <v-checkbox
              v-model="Fields.CausesCancellation"
              style="margin-bottom: -1em"
            >
              <template v-slot:label>
                <div class="pt-1 ml-3 text-dark font-weight-normal">
                  {{ $t("INCIDENTS.TYPES.CANCELS_2") }}
                </div>
              </template>
            </v-checkbox>
          </div>
        </div>
      </v-form>

      <div class="row button-row">
        <button
          class="btn btn-pill btn-white button-form button-form-primary mr-9"
          @click="$router.push('/manager/incidents/types')"
        >
          <inline-svg src="/media/icons/arrow-return-primary.svg" class="mr-3" />
          {{ $t("GENERIC.BACK") }}
        </button>

        <button
          class="btn btn-pill btn-tertiary button-form"
          :disabled="!Valid"
          @click="onSubmitForm"
        >
          {{ $t("GENERIC.SAVE") }}
        </button>
      </div>
    </div>
  </div>
  <!-- end::IncidentTypes edit -->
</template>

<script>
import i18nService from "@/core/services/i18n.service.js";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import IncidentTypeService from "@/core/services/api/v2/incidenttype.service";

export default {
  name: "IncidentTypesEdit",
  data() {
    return {
      IncidentTypeID: 0,
      LanguageList: i18nService.languages,
      Valid: true,
      Fields: {
        Name: {},
        CausesCancellation: false
      },
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD")
      }
    };
  },
  mounted() {
    // Edit (a certain "id") or create new?
    if (this.$route.params.id) {
      this.IncidentTypeID = parseInt(this.$route.params.id);
      this.loadIncidentTypeData();
    } else {
      this.IncidentTypeID = -1;
    }
  },
  methods: {
    loadIncidentTypeData() {
      // Show page loader
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      IncidentTypeService.getProfile(this.IncidentTypeID).then(response => {
        if (response === 404) {
          this.$router.push("/manager/incidents/types");
        } else {
          this.IncidentTypeID = response.IncidentTypeID;

          for (var f1 in this.Fields) {
            this.Fields[f1] = response[f1];
          }

          // Remove page loader
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        }
      });
    },

    onSubmitForm(e) {
      e.preventDefault();

      if (this.$refs.formBasic.validate()) {
        // Disable submit button
        this.Valid = false;

        // Send request:
        if (this.IncidentTypeID > 0) {
          // Update IncidentType
          IncidentTypeService.updateProfile(
            this.IncidentTypeID,
            this.Fields
          ).then(statusCode => {
            if (statusCode >= 400) {
              let whyError = this.$i18n.t("GENERIC.TRY_LATER");
              if (statusCode === 400) {
                whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
              }

              this.$bvToast.toast(whyError, {
                title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                variant: "danger",
                solid: true
              });
            } else {
              this.$bvToast.toast(" ", {
                title: this.$i18n.t("GENERIC.SAVE_OK"),
                variant: "success",
                solid: true
              });
            }

            // Reset submit button
            this.Valid = true;
          });
        } else {
          // Create new IncidentType: we get back its ID
          IncidentTypeService.createProfile(this.Fields).then(response => {
            if (response.IncidentTypeID) {
              this.$bvToast.toast(" ", {
                title: this.$i18n.t("GENERIC.SAVE_OK"),
                variant: "success",
                solid: true
              });

              this.IncidentTypeID = response.IncidentTypeID;
              setTimeout(
                () =>
                  this.$router.push(
                    "/manager/incidents/types/edit/" + response.IncidentTypeID
                  ),
                2000
              );
            } else {
              let whyError = this.$i18n.t("GENERIC.TRY_LATER");
              if (response === 400) {
                whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
              }

              this.$bvToast.toast(whyError, {
                title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                variant: "danger",
                solid: true
              });
            }

            // Reset submit button
            this.Valid = true;
          });
        }
      }
    }
  }
};
</script>
